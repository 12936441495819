export const MyProfileVisible = () =>{
    return {
        type: 'PROFILE_VISIBLE'
    }
}
export const MyProfileHidden = () =>{
    return {
        type: 'PROFILE_HIDDEN'
    }
}

