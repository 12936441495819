export  const SetChatColorBlue = () =>{
    return {
        type: 'BLUE_THEME'
    }
}
export  const SetChatColorGreen = () =>{
    return {
        type: 'GREEN_THEME'
    }
}
export  const SetChatColorPink = () =>{
    return {
        type: 'PINK_THEME'
    }
}



