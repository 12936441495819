export const StarsToggleVisible = () =>{
    return {
        type: 'STARS_VISIBLE'
    }
}
export const StarsToggleHidden = () =>{
    return {
        type: 'STARS_HIDDEN'
    }
}

